import { FC, useRef } from 'react'
import styles from './ScrollSequenceStatic.module.scss'
import { Button } from 'components/Phantom/Button'
import { Icon, IconName } from 'components/Phantom/Icon'
import { ScrollSequenceProps } from './types'
import { Img } from 'components/basic/Img'
import { FinancingInfo } from 'components/Financing/Financing'
import { Type } from 'components/Type'
import { useAnimateInOnScroll } from 'components/_hooks/useAnimateInOnScroll'

interface BenefitProps {
	title: string
	description: string
	icon: IconName
}

const benefits: BenefitProps[] = [
	{
		icon: 'MoonLight',
		title: 'Try risk-free for 30 nights',
		description: 'Enjoy the Pod at home, and return it for a full refund if you don’t love it',
	},
	{
		icon: 'DeliveryLight',
		title: 'Free shipping & returns',
		description: 'Free shipping in nearly 30 countries with no-cost, hassle-free returns',
	},
	{
		icon: 'WarrantyLight',
		title: 'Worry-free warranty',
		description: 'Ongoing Pod protection for up to 5 years. Warranty terms apply',
	},
]

const Benefit: FC<{ title: string; description: string; icon: IconName; color: 'black' | 'white'; index: number }> = (props) => {
	const ref = useRef<HTMLLIElement>(null)
	useAnimateInOnScroll(ref, { delay: props.index * 0.1 })

	return (
		<li
			className={styles.benefit}
			ref={ref}
		>
			<Icon
				name={props.icon}
				color={props.color}
				size={32}
			/>
			<Type.Headline5
				as={'h3'}
				animateOnScroll={false}
			>
				{props.title}
			</Type.Headline5>
			<Type.Body1 animateOnScroll={false}>{props.description}</Type.Body1>
		</li>
	)
}

export const Benefits = () => {
	return (
		<div className={styles.bottom_container}>
			<div className={styles.benefits_wrapper}>
				<ul className={styles.benefits}>
					{benefits.map((b, i) => (
						<Benefit
							key={i}
							index={i}
							title={b.title}
							description={b.description}
							icon={b.icon}
							color={'black'}
						/>
					))}
				</ul>
			</div>

			<div className={styles.bottom_text}>
				<Type.Body2>
					We currently ship to the following regions. United States <em>(excluding Alaska, Hawaii, Puerto Rico and other US territories)</em>, European Union{' '}
					<em>(excluding Cyprus, Greece and Malta)</em> Canada, United Kingdom and Australia.
				</Type.Body2>
			</div>
		</div>
	)
}

export const ScrollSequenceStatic: FC<ScrollSequenceProps> = (props) => {
	const { title = 'Get Pod 4 Ultra', subtitle = 'Our most powerful Pod yet', ctaUrl = '/product/pod-cover' } = props

	return (
		<div className={styles.container}>
			<div className={styles.top_content}>
				<header className={styles.header}>
					<em>New</em>
					<Type.Headline2>{title}</Type.Headline2>
					<Type.Body1>{subtitle}</Type.Body1>
				</header>
				<div className={styles.top_cta}>
					<div className={styles.cta_container}>
						<Button.White
							id={'scroll_cta'}
							href={ctaUrl}
						>
							Shop now
						</Button.White>
					</div>

					<FinancingInfo
						className={styles.affirm_text}
						color={'white'}
					/>
				</div>
			</div>

			<div className={styles.cropped_image_container}>
				<Img
					src={'https://eight-eightsleep-react.s3.us-east-2.amazonaws.com/assets/pod_hub_4_bluelight.png'}
					alt={'The Pod Hub'}
					className={styles.cropped_image}
					objectFit={'cover'}
				/>
			</div>

			<div className={styles.bottom_cta_container}>
				<Button.White
					id={'scroll_cta_2'}
					href={'/product/pod-cover'}
				>
					Shop now
				</Button.White>
			</div>

			<Benefits />
		</div>
	)
}
